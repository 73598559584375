import '../styles/style.css';
import React, { useEffect, useState } from 'react';
import Results from './results';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
    searchValue, userName, passCode, businessEntityKey, apiUrl, loadingActive, modalMessage, modalVisible,
    modalType, searchResults
} from '../atoms';

export default function Search() {
    // Read only variables
    const user = useRecoilValue(userName);
    const password = useRecoilValue(passCode);
    const businessKey = useRecoilValue(businessEntityKey);
    const url = useRecoilValue(apiUrl);

    // Two way binding
    const [search, setSearch] = useRecoilState(searchValue);
    const [routeResults, setRouteResults] = useRecoilState(searchResults);
    const [searchError, setSearchError] = useState(false);
    const [inputSearchType, setinputSearchType] = useState(false);
    const [searchType, setSearchType] = useState(false);
    const [searchErrorMessage, setSearchErrorMessage] = useState("");
    const [isLoadRegions, setIsLoadRegions] = useState(true);
    const [regionResults, setRegionResults] = useState(null);
    const [region, setRegion] = useState(null);
    const [sessionResults, setSessionResults] = useState(null);

    // Sets variable values
    const setLoading = useSetRecoilState(loadingActive);
    const setModalMessage = useSetRecoilState(modalMessage);
    const setModalVisible = useSetRecoilState(modalVisible);
    const setModalType = useSetRecoilState(modalType);

    const UpdateSearchValue = (event) => {
        setSearchError(false);
        setSearchErrorMessage("");
        setSearch(event.target.value);

        if (event.target.value === undefined || event.target.value === "") {
            setSearchError(true);
            setSearchErrorMessage("The search field cannot be empty or blank.");
        }
    }

    const SwitchinputSearchType = () => {
        var isChecked = document.getElementById("isPhoneSearch").checked;

        if (isChecked === false) {
            setinputSearchType(true);
        }
        else {
            setinputSearchType(false);
        }
    }

    const SwitchSearchType = () => {
        var isChecked = document.getElementById("isInputSearch").checked;
        setRouteResults(undefined);

        if (isChecked === false) {
            setSearchType(true);
        }
        else {
            setSearchType(false);
        }
    }

    const showError = (message) => {
        setLoading(false); // hide loading animation
        setModalMessage(message);
        setModalVisible(true);
        setModalType("error");
        setLoading(false); // hide loading animation
    }

    const CheckKeySearch = (event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            SearchRoute();
        }
    }

    const SearchRoute = () => {
        setLoading(true); // show loading animation

        if (search === undefined || search.trim() === "") {
            showError("The search field cannot be empty or blank.");
        }
        else {
            // Do the search
            let jsonBody = {};
            jsonBody.username = user;
            jsonBody.password = password;
            jsonBody.businessUnitEntityKey = businessKey;

            if (inputSearchType) {
                jsonBody.phone = search;

                fetch(url + "GetDevices",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(jsonBody),
                    }).then((response) => response.json())
                    .then((data) => {
                        setLoading(false); // hide loading animation

                        if (data) {
                            setRouteResults(data.items);
                        }
                        else {
                            setRouteResults(undefined);
                            showError("Phone route search failed to complete correctly.");
                        }
                    })
                    .catch((error) => {
                        showError(error);
                    });
            }
            else {
                jsonBody.routeId = search;

                fetch(url + "GetRoutes",
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(jsonBody),
                    }).then((response) => response.json())
                    .then((data) => {
                        setLoading(false); // hide loading animation

                        if (data) {
                            setRouteResults(data.items);
                        }
                        else {
                            setRouteResults(undefined);
                            showError("Route search failed to complete correctly.");
                        }
                    })
                    .catch((error) => {
                        showError(error);
                    });
            }
        }
    }

    const selectRegion = (item) => {
        setRegion(item);
        setSessionResults(undefined);

        // Load the routing sessions
        setLoading(true); // show loading animation

        // Do the search
        let jsonBody = {};
        jsonBody.username = user;
        jsonBody.password = password;
        jsonBody.businessUnitEntityKey = businessKey;
        jsonBody.regionEntityKey = item.regionEntityKey;

        fetch(url + "GetRoutingSessions",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jsonBody),
            }).then((response) => response.json())
            .then((data) => {
                setLoading(false); // hide loading animation

                if (data && data.sessions.length > 0) {
                    setSessionResults(data.sessions);
                }
                else {
                    setSessionResults(undefined);
                    showError("No session results were found for this region.");
                }
            })
            .catch((error) => {
                showError(error);
            });
    }

    const selectRoutingSession = (item) => {
        setRouteResults(undefined);

        // Load the routing sessions
        setLoading(true); // show loading animation

        // Do the search
        let jsonBody = {};
        jsonBody.username = user;
        jsonBody.password = password;
        jsonBody.businessUnitEntityKey = businessKey;
        jsonBody.regionEntityKey = region.regionEntityKey;
        jsonBody.sessionEntityKey = item.sessionEntityKey;

        fetch(url + "GetSessionRoutes",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jsonBody),
            }).then((response) => response.json())
            .then((data) => {
                setLoading(false); // hide loading animation

                if (data && data.items != null && data.items.length > 0) {
                    setRouteResults(data.items);
                }
                else {
                    setRouteResults(undefined);
                    showError("No session route results were found for this region.");
                }
            })
            .catch((error) => {
                showError(error);
            });
    }

    useEffect(() => {
        if (isLoadRegions) {
            setLoading(true); // show loading animation
            setIsLoadRegions(false);

            // Do the search
            let jsonBody = {};
            jsonBody.username = user;
            jsonBody.password = password;

            fetch(url + "GetRegions",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(jsonBody),
                }).then((response) => response.json())
                .then((data) => {
                    setLoading(false); // hide loading animation

                    if (data) {
                        setRegionResults(data.region);
                    }
                    else {
                        setRouteResults(undefined);
                        showError("Region loading failed to complete correctly.");
                    }
                })
                .catch((error) => {
                    showError(error);
                });
        }

    }, [isLoadRegions, setIsLoadRegions, setRegionResults, password, setLoading, setRouteResults, showError, url, user]);

    return (
        <>
            <div class="search-bar-mini h-float">
                <div className="search-bar-slider">
                    <div className="search-bar-slider-label">Text Search</div>
                    <div className="switch" onClick={SwitchSearchType}>
                        <input type="checkbox" id="isInputSearch" checked={searchType} />
                        <span className="slider round"></span>
                    </div>
                    <div className="search-bar-slider-label">Filter Search</div>
                </div>
            </div>
            {!searchType ?
                <div className="search-bar search-bar-reg h-float">
                    <label htmlFor="searchBarInput">Search {!inputSearchType ? " route identifier " : " phone number "}</label>
                    {searchError ?
                        <input className="search-bar-error" id="searchBarInput" placeholder={!inputSearchType ? "Enter a route identifier " : "Enter a phone number "} onInput={UpdateSearchValue} value={search} />
                        :
                        <input className="search-bar-input" id="searchBarInput" placeholder={!inputSearchType ? "Enter a route identifier " : "Enter a phone number "} onInput={UpdateSearchValue} onKeyUp={CheckKeySearch} value={search} />
                    }

                    <button className="search-bar-button" onClick={SearchRoute}><FontAwesomeIcon icon={faSearch} /></button>
                    <div className="search-bar-slider">
                        <div className="search-bar-slider-label">Route Identifier</div>
                        <div className="switch" onClick={SwitchinputSearchType}>
                            <input type="checkbox" id="isPhoneSearch" checked={inputSearchType} />
                            <span className="slider round"></span>
                        </div>
                        <div className="search-bar-slider-label">Phone</div>
                    </div>
                </div>
                : // This is the filtering by regions, then sessions, then seeing route list
                <div class="">
                    <div className="search-bar search-bar-title h-float">
                        <div class="width-20 format-center dropdown-label">Region</div>
                        <div class="width-20 format-center dropdown-label">Routing Session</div>
                        <div class="width-60 format-center"></div>
                    </div>
                    <div className="search-bar search-bar-filter h-float">
                        <div class="width-20 format-center dropdown-spacing">
                            {regionResults && regionResults.length > 0 ?
                                <select className='stop-input'>
                                    <option value=""></option>
                                    {regionResults.map((item, i) => (
                                        <option value={item.regionEntityKey} onClick={() => selectRegion(item)}>{item.region}</option>
                                    ))
                                    }
                                </select>
                                :
                                <select className='stop-input'>
                                    <option value=""></option>
                                </select>
                            }
                        </div>
                        <div class="width-20 format-center dropdown-spacing">
                            {sessionResults && sessionResults.length > 0 ?
                                <select className='stop-input'>
                                    <option value=""></option>
                                    {sessionResults.map((item, i) => (
                                        <option value={item.sessionEntityKey} onClick={() => selectRoutingSession(item)}>{item.session + ": " +  item.description}</option>
                                    ))
                                    }
                                </select>
                                :
                                <select className='stop-input'>
                                    <option value=""></option>
                                </select>
                            }
                        </div>
                        <div class="width-60 format-center"></div>
                    </div>
                </div>
            }

            <div className="search-bar-error-message">{searchErrorMessage}</div>
            {routeResults !== undefined &&
                <Results></Results>
            }
        </>
    );
}
