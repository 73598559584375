// Library required
import { atom } from 'recoil';

/* Web request items */
export const apiUrl = atom({
    key: 'apiUrl',
    default: "https://route-recovery-api.dev.cld.omnitracs.com/",
    //default: "https://localhost:5001/",
});

/* Login variables */
export const isLoggedIn = atom({
    key: 'isLoggedIn',
    default: false,
});

export const passCode = atom({
    key: 'passCode',
    default: "",
});

export const businessEntityKey = atom({
    key: 'businessEntityKey',
    default: "",
});

/* Used for the header */
export const userName = atom({
    key: 'userName',
    default: "",
});

export const loadingActive = atom({
    key: 'loadingActive',
    default: false,
});

export const modalType = atom({
    key: 'modalType',
    default: "",
});

export const modalMessage = atom({
    key: 'modalMessage',
    default: "Initial default message.",
});

export const modalVisible = atom({
    key: 'modalVisible',
    default: false,
});

/* Used for the pages */
export const pageSelected = atom({
    key: 'pageSelected',
    default: "login",
});


/* Used for searching */
export const searchValue = atom({
    key: 'searchValue',
    default: "",
});

export const searchResults = atom({
    key: 'searchResults',
    default: undefined,
});

/* Used for route management */
export const routeSelected = atom({
    key: 'routeSelected',
    default: undefined,
});

export const displayRoute = atom({
    key: 'displayRoute',
    default: false,
});

export const loadedRoute = atom({
    key: 'loadedRoute',
    default: undefined,
});

export const cancelCodes = atom({
    key: 'cancelCodes',
    default: undefined,
});