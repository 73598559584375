import './App.css';
import Header from './pages/header';
import Page from './pages/page';
import Footer from './pages/footer';
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <RecoilRoot>
    <Header></Header>
    <Page></Page>
    <Footer></Footer>
    </RecoilRoot>
  );
}

export default App;
