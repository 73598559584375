import '../styles/style.css';
import React from 'react';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';
import { userName, passCode, businessEntityKey, apiUrl, searchResults, routeSelected, displayRoute,
    loadedRoute, loadingActive, modalMessage, modalVisible, modalType, cancelCodes
 } from '../atoms';
import Route from './route';

export default function Results() {
    // Read only variables
    const user = useRecoilValue(userName);
    const password = useRecoilValue(passCode);
    const businessKey = useRecoilValue(businessEntityKey);
    const url = useRecoilValue(apiUrl);
    const routeResults = useRecoilValue(searchResults);

    
    // Two way binding
    const [showRoute, setShowRoute] = useRecoilState(displayRoute);

    // Sets variable values
    const setSelectRoute = useSetRecoilState(routeSelected);
    const setRoute = useSetRecoilState(loadedRoute);
    const setLoading = useSetRecoilState(loadingActive);
    const setModalMessage = useSetRecoilState(modalMessage);
    const setModalVisible = useSetRecoilState(modalVisible);
    const setModalType = useSetRecoilState(modalType);
    const setCancelCodes = useSetRecoilState(cancelCodes);

    const showError = (message) => {
        setLoading(false); // hide loading animation
        setModalMessage(message);
        setModalVisible(true);
        setModalType("error");
        setLoading(false); // hide loading animation
    }

    const ConvertToLocal = (oTimestamp) => {
        var newTime = undefined;

        // If empty, return undefined
        if(oTimestamp === null || oTimestamp === undefined) {
            return undefined;
        }
        else { // Convert to local time by creating new object
            newTime = new Date (oTimestamp.value+'Z');
        }

        return newTime.getFullYear() + "-" + ("0" + (newTime.getMonth()+1)).slice(-2) + "-" + ("0" + newTime.getDate()).slice(-2)
        + "T" + ("0" + newTime.getHours()).slice(-2) + ":" + ("0" + newTime.getMinutes()).slice(-2);
    }

    const SimplifyRoute = (data) => {
        let stops = [];
        let seq = 1;

        data.stopActuals.forEach((s) => {
            let item = {};

            if(s.stopType === "OriginDepotStop") { // Origin stop
                item.stopNum = seq;
                item.stopType = "origin";
                item.locdescription = s.originDepotStopInfo.locationInfo.description;
                item.locidentifier = s.originDepotStopInfo.locationInfo.identity.identifier;
                item.locentityKey = s.originDepotStopInfo.locationInfo.identity.entityKey;
                item.arrival = ConvertToLocal(s.originDepotStopInfo.startTimestamp);
                item.departure = ConvertToLocal(s.originDepotStopInfo.departureTimestamp);

                stops.push(item);
                seq++;
            }
            else if(s.stopType === "ServiceableStop") { // Service stop
                item.stopNum = seq;
                item.stopType = "service";
                item.locdescription = s.serviceableStopInfo.locationInfo.description;
                item.locidentifier = s.serviceableStopInfo.locationInfo.identity.identifier;
                item.locentityKey = s.serviceableStopInfo.locationInfo.identity.entityKey;
                item.locaddress = s.serviceableStopInfo.locationInfo.address.addressLine1;
                item.loccity = s.serviceableStopInfo.locationInfo.address.city;
                item.locstate = s.serviceableStopInfo.locationInfo.address.stateOrProvince;
                item.loczipcode = s.serviceableStopInfo.locationInfo.address.postalCode;  
                item.cancelled = s.serviceableStopInfo.isCancelled !== null ? false : true; 
                item.locked = s.serviceableStopInfo.isCancelled !== null ? false : true; 

                // Tracks changes
                item.save = false;
                item.cancelreasonValue = undefined; 
                item.cancelreason = undefined;                    
                item.changed = false;              
                
                item.arrival = ConvertToLocal(s.serviceableStopInfo.arrivalTimestamp);
                item.departure = ConvertToLocal(s.serviceableStopInfo.departureTimestamp);

                stops.push(item);
                seq++;
            }
            else if(s.stopType === "DestinationDepotStop") { // Destination stop
                item.stopNum = seq;
                item.stopType = "destination";
                item.locdescription = s.destinationDepotStopInfo.locationInfo.description;
                item.locidentifier = s.destinationDepotStopInfo.locationInfo.identity.identifier;
                item.locentityKey = s.destinationDepotStopInfo.locationInfo.identity.entityKey;
                item.arrival = ConvertToLocal(s.destinationDepotStopInfo.arrivalTimestamp);
                item.departure = ConvertToLocal(s.destinationDepotStopInfo.completeTimestamp);

                stops.push(item);
                seq++;
            }
        });

        setRoute(stops)
    }

    const GetCancelCodes = () => {
        let jBody = {};
        jBody.username = user;
        jBody.password = password;
        jBody.businessEntityKey = businessKey;

        fetch(url + "GetStopReasonCancelCode",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jBody),
            }).then((response) => response.json())
            .then((data) => {
                setLoading(false); // hide loading animation

                if (data) {
                    setCancelCodes(data.items); // Save the cancel codes
                }
                else {
                    setRoute(undefined);
                    showError("Loading the route failed to complete correctly.");
                }
            })
            .catch((error) => {
                showError(error);
                console.log(error);
            });
    }

    const OpenRoute = (item, event) => {
        setLoading(true); // show loading animation

        // Get the cancel codes also
        GetCancelCodes();

        // Get the route for editing
        let jsonBody = {};
        jsonBody.username = user;
        jsonBody.password = password;
        jsonBody.businessEntityKey = businessKey;
        jsonBody.routeEntity = item.entityKey; // route entity key

        fetch(url + "GetSingleRoute",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(jsonBody),
            }).then((response) => response.json())
            .then((data) => {
                if (data && data.item.returnCode === "200") {
                    data.item.changed = false;
                    setSelectRoute(data.item); // Assign route key for other work
                    SimplifyRoute(data.item);
                    setShowRoute(true);
                }
                else {
                    setRoute(undefined);
                    showError(data.item.returnMessage);
                }

                setLoading(false); // hide loading animation
            })
            .catch((error) => {
                showError(error);
                console.log(error);
            });
    }

    return (
        <>
        <div className="results h-float">
            <div className="div-row div-row-header">
                <div className="format-center width-50">Route Identifier</div>
                <div className="format-center width-12">Entitiy Key</div>
                <div className="format-center width-12">Description</div>
                <div className="format-center width-12">State</div>
                <div className="format-center width-12">Route Phase</div>
            </div>
            {routeResults !== undefined && routeResults.length > 0 ? routeResults.map((item, i) => (
                <div className="div-row div-row-data" onClick={(event) => OpenRoute(item, event)} value={item.entityKey}>
                    <div className="format-center width-50">{item.routeIdentifier}</div>
                    <div className="format-center width-12">{item.entityKey}</div>
                    <div className="format-center width-12">{item.description}</div>
                    <div className="format-center width-12">{item.state}</div>
                    <div className="format-center width-12">{item.routePhase}</div>
                </div>
            ))
                :
                <div className="div-row">No results were found</div>
            }
        </div>
        {showRoute &&
            <Route></Route>
        }
        </>
    );
}
