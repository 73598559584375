import '../styles/style.css';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { pageSelected } from '../atoms';
import Login from './login';
import Search from './search';
import Results from './results';
import Error from './Error';

export default function Footer() {
    const page = useRecoilValue(pageSelected);

    return (
        <div className="page">
            {page === "login" ?
            <Login></Login>
            : page === "search" ?
            <Search></Search>
            : page === "results" ?
            <Results></Results>
            :
            <Error></Error>
        }
        </div>
    );
}
