import logo from '../images/logo.png';
import '../styles/style.css';
import React from 'react';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { isLoggedIn,   userName, passCode, pageSelected } from '../atoms';

export default function Header() {
    // Read only variables

    // Two way binding
    const [loggedIn, setLoggedIn] = useRecoilState(isLoggedIn);

    // Set values only
    const setUser = useSetRecoilState(userName);
    const setPassword = useSetRecoilState(passCode);
    const setPage = useSetRecoilState(pageSelected);

    const LogoutUser = () => {
        setLoggedIn(false);
        setUser(undefined);
        setPassword(undefined);
        setPage("login");
    }


    return (
        <>
            <div className="header-bar">
                <div className="header-app v-float" >
                    <img alt="Logo" src={logo} transition="scale-transition" className="header-app-logo v-float" v-if="authenticated" />
                </div>
                <div className="app-title v-float">
                    Mobile Route Recovery App
                </div>

                {/*<select row
                v-model="selectedLang"
                v-if="langs.length > 1"
                className="langPicker"
            >
                <option value="lang" v-for="(lang, idx) in langs" key="idx">
                     /*lang.toUpperCase() 
                </option>
            </select>*/}

                <div className="app-login v-float">
                    {loggedIn &&
                        <button className="button-logout" onClick={LogoutUser}  >
                            Logout
                        </button>
                    }
                </div>
            </div>
        </>
    );
}
