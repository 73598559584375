import '../styles/style.css';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { useRecoilValue, useRecoilState } from 'recoil';
import { isLoggedIn, userName, loadingActive, modalMessage, modalVisible, modalType,} from '../atoms';

export default function Footer() {
    const loggedIn = useRecoilValue(isLoggedIn);
    const user = useRecoilValue(userName);
    const mType = useRecoilValue(modalType);

    // Two way binding
    const [mMessage, setMMessage] = useRecoilState(modalMessage);
    const [displayModal, setDisplayModal] = useRecoilState(modalVisible);
    const [isLoading, setIsLoading] = useRecoilState(loadingActive);

    const CloseModal = () => {
        setMMessage("");
        setDisplayModal(false);
        setIsLoading(false);
    }

    return (
        <>
            <div className="footer-bar">
                <div style={{ width: "80%" }}></div>
                <div className="footer-user v-float">
                    {loggedIn &&
                        <div>Logged in as {user}</div>
                    }
                </div>
            </div>

            {/* Shown when loading something */}
            {isLoading &&
                <div className="loading">
                    <div className="loading-container"></div>
                </div>
            }
            {/* Shown when error message is loaded */}
            {displayModal &&
                <div className="modal-block">
                    <div className="modal-block-back-short">
                        {mType === "error" ?
                            <div className="modal-header modal-header-large modal-header-error">
                                <div className="v-float width-100">Error</div>
                                <div className="modal-close modal-close-red"><button onClick={CloseModal}><FontAwesomeIcon icon={faX} /></button></div>
                            </div>
                            :
                            <div className="modal-header modal-header-large modal-header-regular">
                                <div className="v-float width-100">Success</div>
                                <div className="modal-close modal-close-purple"><button onClick={CloseModal}><FontAwesomeIcon icon={faX} /></button></div>
                            </div>
                        }
                        <div className="modal-body">
                            <div className="modal-row">
                                {mMessage}
                            </div>
                            <div className="modal-row">
                                <div className="modal-row-button modal-row-button-error width-100">
                                    <button onClick={CloseModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}
